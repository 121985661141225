import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { ContentLayout, QuickBottomNavigationWrapper } from '../components/';
import Layout from '../components/layout';
import RichTextRenderer from '../components/RichTextRenderer';
import SEO from '../components/seo';
import styles from '../stylesheets/pages/imprint.module.scss';
import { getLocalizedDataFromContentfulEdges } from '../utils';

const Imprint = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		{
			allContentfulImprint {
				edges {
					node {
						node_locale
						header
						content {
							raw
						}
						policy {
							raw
						}
					}
				}
			}
		}
	`);

	const { allContentfulImprint } = data;
	const {
		node: { header, content, policy },
	} = getLocalizedDataFromContentfulEdges(allContentfulImprint.edges, intl.locale)[0];

	return (
		<Layout>
			<SEO title="HelloFresh - imprint" />
			<QuickBottomNavigationWrapper>
				<div className={styles.wrapper}>
					<ContentLayout>
						<h1>{header}</h1>
						<div className={styles.container}>
							<section className={styles.info}>{RichTextRenderer(content)}</section>
							<section className={styles.policy}>{RichTextRenderer(policy)}</section>
						</div>
					</ContentLayout>
				</div>
			</QuickBottomNavigationWrapper>
		</Layout>
	);
};

export default Imprint;
